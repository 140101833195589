import '../styles/index.scss';

let Flickity = require('flickity');
require('flickity-fullscreen');

var Victor = require('victor');

window.allowDesktop = false;
window.paused = false;

window.entertowin = false;


window.showIntro = false;
window.showAnimation = false;

window.frame_rate = 42; // 24fps

window.allSpriteIds = ["allthefeels", "bubble", "cloud", "heart-red", "heart-green", "heart-yellow", "rainbow", "sphere", "spheres", "spiral-blue", "spiral-yellow"];
window.offScreen =  [$(window).width()/2, -500];

let getOffScreen = () => {
	return [ (Math.random() * $(window).width()), -500 + (Math.random()*-100)];
}







function getQueryVariable(variable) {

	var query = window.location.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) == variable) {
			return decodeURIComponent(pair[1]);
		}
	}
	
	return null;
}

let mode = getQueryVariable("mode");

window.register = "http://carmax-bow-forms-staging.s3-website-us-east-1.amazonaws.com/register.html";

switch (mode) {
	
	case "staging":
		window.register = "http://carmax-bow-forms-staging.s3-website-us-east-1.amazonaws.com/register.html"; //staging
		break;
	case "dev":
		window.register = "http://carmax-forms.dev.onepixeloff.com/register.html";
		break;
	default:
		break;
}

if ($("#registration-form").length)
	$("#registration-form").attr("src", window.register);

var rs_iframe = null;
var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
var eventer = window[eventMethod];
var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

eventer(messageEvent, (e) => {


	if (e.data && e.data.toString().indexOf('resize::') != -1) {
		if ($("#registration-form").length)
		{
			rs_iframe = $("#registration-form")[0];
		}
		
		
		if (rs_iframe != null)
		{
			var height = e.data.toString().replace('resize::', '');
			
			let actualHeight = (parseInt(height) + 30);
			if (actualHeight < 100)
				actualHeight = 100;
			
			rs_iframe.style.height = actualHeight+'px';
		}
	}
	
} ,false);





$(()=>{
	// add youtube api

	var tag = document.createElement('script');
	
	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	
	
	window.tutorial_player = null;
	window.onYouTubeIframeAPIReady = () => {
		console.log("youtube is ready");
		window.tutorial_player = new YT.Player('tutorial_video', {
		  height: '390',
		  width: '640',
		  videoId: '-X4Wrz5aDEk',
		  playerVars: {
			'playsinline': 1,
			'rel': 0,
			'modestbranding': 0,
			'fs': 0
			
		  },
		  events: {
			'onReady': onPlayerReady
		  }
		});
	}
	
	
    let onPlayerReady = (event) => {
        window.tutorial_video_ready = true;
    }

    



	if (navigator.userAgent.indexOf("Firefox") > 0 || navigator.userAgent.indexOf("Windows") > 0  || navigator.userAgent.indexOf("Android") > 0)
	{
		$("body").addClass("typefix");
	}
	
	let intro_video = $("#intro_video")[0];
	
	
    
    intro_video.addEventListener("play", (e)=>{
    	
    	if (window.paused)
    	{
    		window.resumeIntro = true;
    		intro_video.pause();
    	}
    		
    });
    
    intro_video.addEventListener("pause", (e)=>{
    });
    
    intro_video.addEventListener("ended", (e)=>{
    	
    	window.setPageState("intro-2", true);
    });
    
    window.resumeIntro = false;
    window.resumeTutorial = false;
    
    let handlePause = ()=>{
    	if (! window.paused)
    	{
			window.resumeIntro = ! intro_video.paused;
			
			$(".page").removeClass("hide");
			
			if (window.resumeIntro)
				intro_video.pause();
				
			window.paused = true;
		}
    };
    
    let handleUnpause = ()=>{
    	if (window.paused)
    	{
    		if (window.resumeIntro)
    			intro_video.play();
    		
    		window.paused = false;
    	}
    };
	
	
	let resize = ()=>{
		let maxWidth = 750;
		
		let doScale = true;
		
		if ($(window).width() > $(window).height() && ! window.allowDesktop)
		{
			// landscape -- only show warning on mobile
			
			if ($(window).height() > maxWidth && ! window.allowDesktop)
			{
				// desktop warning
				handlePause();
				
				
				$(".landscapeWarning").hide();
				$(".intro_video_holder").hide();
				$(".page").addClass("hide");
				$(".desktopWarning").show();
				
				
				
				doScale = false;
				
			}
			else
			{
				// show landscape warning
				
				
				handlePause();
				
				$(".landscapeWarning").show();
				$(".intro_video_holder").show();
				$(".page").removeClass("hide");
				$(".desktopWarning").hide();
				
				doScale = true;
				
			}
			
		}
		else
		{
			// portrait
			
			if ($(window).width() > maxWidth && ! window.allowDesktop)
			{
				// show desktop warning
				handlePause();
				
				$(".landscapeWarning").hide();
				$(".intro_video_holder").hide();
				$(".page").addClass("hide");
				$(".desktopWarning").show();
				
				doScale = false;
				
			}
			else
			{
				handleUnpause();

				$(".landscapeWarning").hide();
				$(".intro_video_holder").show();
				$(".page").removeClass("hide");
				$(".desktopWarning").hide();
				
				doScale = true;				
			}
        }
        let scale = 1;
        
        
        let scaledWidth = 1;
        
        if (doScale)
        {
        	
        	
        	if ($(window).width()/$(window).height() >= 490/970)
			{
				// 616
				
				// width/375
				// height/
				
				let height = $(window).height();
		
				scale = height/742;
				
				scaledWidth = $(window).width() * (1/scale);
				
				//console.log("width scale 1 ",scale)
			}
			else
			{
				let width = $(window).width();
				if (width > maxWidth)
					width = maxWidth;
		
				scale = width/375;
				
				//scaledWidth = Math.round(375 * (scale));
				scaledWidth = $(window).width() * (1/scale);
				
				//console.log("width scale 2 ",scale)
			}
			
			
			$("div.page").attr("style", "width: "+scaledWidth+"px; transform-origin: top center; transform: translate(-50%,0) scale("+scale+","+scale+")");
			$("div.header").attr("style", "transform-origin: top center; transform: translate(-50%,0) scale("+scale+","+scale+")");
			$("div.footer").attr("style", "transform-origin: bottom center; transform: translate(-50%,0) scale("+scale+","+scale+")");
			
			window.content_scale = scale;
        }
        else
        {
        	window.content_scale = 1;
        	
        	$("div.page, div.header, div.footer").attr("style","");
        }
        
        let wWidth = $(window).width();
        let wHeight = $(window).height();
        
        
        if ($(window).width()/$(window).height() >= 490/970)
		{
			let hScaled = (742/wHeight)*wHeight;
			let wScaled = 375*(1/scale);
			
			$(".full-height").attr("style", "height: "+hScaled+"px;"); //" width: "+wScaled+"px;");
			$(".full-height-min").attr("style", "min-height: "+hScaled+"px;");
		}
		else
		{        
			let hScaled = (375/wWidth)*wHeight;
			let wScaled = 375*(1/scale);
			
			$(".full-height").attr("style", "height: "+hScaled+"px;"); //px; width: "+wScaled+"px;");
			$(".full-height-min").attr("style", "min-height: "+hScaled+"px;");
        }
        
        if (window.socialCarousel)
        {
        	window.socialCarousel.resize();
        	
        	setTimeout(()=>{
				window.socialCarousel.resize();
			}, 500);
        }
        
        if (window.ugcCarousel)
        {
        	window.ugcCarousel.resize();
        	
        	setTimeout(()=>{
				window.ugcCarousel.resize();
			}, 500);
        }
    };
    
   
    
    
    
    
    $("body").attr("style", "");
    
    
    $(".desktopWarning a#allowDesktop").on("click", (e)=>{
    	
    	gtag('event', 'continue_to_desktop', {});
    	
    	window.allowDesktop = true;
    	
    	resize();
    	handleUnpause();
    	
    	e.preventDefault();
    });
    
    
    $("#intro .prompt").on("click", (e)=>{
    	gtag('event', 'start_journey', {});
    	
    	$("body").animate({ scrollTop: $('#celebrate').offset().top }, 1000);
    	
    	e.preventDefault();
    });
    
    $("#celebrate input#agree").on("change", (e)=>{
    	
    	// console.log("checked", $("#celebrate input#agree").is(":checked"))
    	
    	gtag('event', 'confirm_check', {});

    	
    	if ($("#celebrate input#agree").is(":checked"))
    	{
    		$("#celebrate .go-to-filter").removeClass("disabled");
    		$("#celebrate .show-tutorial").removeClass("disabled");
    		
    		$("#showitoff").removeClass("hide");
    		
    		if (window.socialCarousel)
	    		window.socialCarousel.resize();
    	}
    	else
    	{
    		$("#celebrate .go-to-filter").addClass("disabled");
    		$("#celebrate .show-tutorial").addClass("disabled");
    		
    		$("#showitoff").addClass("hide");
    	}
    });
    
    if ($("#celebrate input#agree").is(":checked"))
	{
		$("#celebrate .go-to-filter").removeClass("disabled");
		$("#celebrate .show-tutorial").removeClass("disabled");
		
		$("#showitoff").removeClass("hide");
		
		if (window.socialCarousel)
			window.socialCarousel.resize();
	}
	else
	{
		$("#celebrate .go-to-filter").addClass("disabled");
		$("#celebrate .show-tutorial").addClass("disabled");
		
		$("#showitoff").addClass("hide");
	}
    
    $("#celebrate .go-to-filter").on("click", (e)=>{
    	
    	if (! $("#celebrate .go-to-filter").hasClass("disabled"))
    	{
    		gtag('event', 'filter_button', {});
    		
    		$("body").animate({ scrollTop: $('#showitoff').position().top }, 1000);
    		//console.log("scrollto", $('#showitoff').offset().top);
    	}
    	
    	e.preventDefault();
    });
    
    $("#celebrate .show-tutorial").on("click", (e)=>{
    	if (! $("#celebrate .show-tutorial").hasClass("disabled"))
    	{
    		gtag('event', 'tutorial_button', {});
    		
    		$("#tutorial_video source").attr("src", $("#tutorial_video source").attr("data-actualsrc"));
    	
			$("#tutorialVideo").show();
			
			window.tutorial_player.seekTo(0);
			window.tutorial_player.playVideo();
    	}
    	
    	e.preventDefault();
    });
    
    $("#tutorialVideo .close").on("click", (e)=>{
    	$("#tutorialVideo").hide();
    	
    	window.tutorial_player.seekTo(0);
    	window.tutorial_player.stopVideo();
    	
    	e.preventDefault();
    });
    
    
    
    window.socialCarousel = new Flickity( ".page .content #showitoff .carousel", {
		cellAlign: 'center',
		prevNextButtons: true,
		wrapAround: true,
		percentPosition: false,
		pageDots: false,
		draggable: true,
		imagesLoaded: true,
		contain: true
	});
	
	window.socialCarousel.on( 'change', ( index ) => {
		gtag('event', 'sm_scroll', {});
	});
	
	
	
	if ($(".page .content #explore .carousel").length)
	{
	
		window.ugcCarousel = new Flickity( ".page .content #explore .carousel", {
			cellAlign: 'center',
			prevNextButtons: true,
			wrapAround: true,
			percentPosition: false,
			pageDots: true,
			draggable: true,
			imagesLoaded: true,
			contain: true,
			//fullscreen: true,
			lazyLoad: 1
		});
		
		window.ugcCarousel.on( 'staticClick', ()=>{
			console.log("show item");
			
			if ($("#fullScreenImage").hasClass("open"))
			{
				$("#fullScreenImage").hide();
				$("#fullScreenImage").removeClass("open");
			}
			else
			{
				$("#fullScreenImage img").attr("src", $(".page .content #explore .carousel .is-selected img").attr("src"));
				
			
				$("#fullScreenImage").show();
				$("#fullScreenImage").addClass("open");
			}
		});
		
		window.ugcCarousel.on( 'change', ( index ) => {
			gtag('event', 'ugc_scroll', {});
		});
		
	}
	
	$("#fullScreenImage .close").on("click", (e)=>{
    	$("#fullScreenImage").hide();
    	$("#fullScreenImage").removeClass("open");
    	e.preventDefault();
    });
	
	

    
    let showHeader = ()=>{
    
		$(".header").show();
		$(".footer").show();
		
		setTimeout(()=>{
			$(".header").addClass("show");
			$(".footer").addClass("show");
		}, 100);
    }
    let hideHeader = ()=>{
    	$(".header").removeClass("show");
		$(".footer").removeClass("show");
		
		setTimeout(()=>{
			$(".header").hide();
			$(".footer").hide();
		}, 1100);
    }
    
    
    window.pageState = "start";
    
    window.timeouts = [];
    
    let bubble = () =>{
    	if (window.showAnimation)
    	{
			console.log("bubble");
			
			// activeSprites = ["allthefeels", "bubbles", "cloud", "heart-red", "heart-green", "heart-yellow", "rainbow", "sphere", "spheres", "spiral-blue", "spiral-yellow"];
			
			for (let i=0; i<window.timeouts.length; i++)
			{
				clearTimeout(window.timeouts[i]);
			}
			
			window.timeouts = [];
			
			for (let s=0; s<allSpriteIds.length; s++)
			{	
				// position
				let positionsprite = $("#"+allSpriteIds[s]);
				
				if (positionsprite.length != 0)
				{
					positionsprite.removeClass("animate");
					
					let startX = ($(window).width() + 200) * Math.random() - 100;
					let startY = $("body").scrollTop() + $(window).height() + 200 + Math.random()*600; // + 100 + (Math.random()*500);
					
					let endX = ($(window).width() + 200) * Math.random() - 100;
					let endY = $("body").scrollTop() - 200 - (Math.random()*600);
					
					let seconds = (Math.random()*1) + 6
					
					window.timeouts.push(setTimeout(()=>{
						positionsprite.attr("style", "top: "+startY+"px; left: "+startX+"px;");
						positionsprite.show();
					}, 100));
					
					window.timeouts.push(setTimeout(()=>{
						positionsprite.addClass("animate");
					}, 200));
					
					window.timeouts.push(setTimeout(()=>{
						positionsprite.attr("style", "top: "+endY+"px; left: "+endX+"px; transition: linear top "+seconds+"s, linear left "+seconds+"s, linear opacity 0.5s;")
					}, 300));
					
					window.timeouts.push(setTimeout(()=>{
						positionsprite.attr("style", "top: "+endY+"px; left: "+endX+"px; transition: linear top "+seconds+"s, linear left "+seconds+"s, linear opacity 0.5s; opacity: 0")
					}, 6000));
					
					window.timeouts.push(setTimeout(()=>{
						positionsprite.hide();
					}, 7300));
				
				}
			}
		}
    }
    
    window.setPageState = (newState, isDown) => {
    	if (newState != window.pageState)
    	{
    		let changed = true;
    		
    		let activeSprites = [];
    		let spriteParent = null;
    		let maxV = 0;
    		
    		switch (newState)
    		{
    			case "pre":
    				$("body").addClass("blue");
    				$("body").removeClass("white");
    				
    				$(".page").addClass("show");
    				$(".page").attr("style", "opacity: 0");
    				
    				hideHeader();
    				
    				break;
    				
    				
    			case "intro-1":
    				if (window.showIntro)
    				{
						$("#intro_video source").attr("src", $("#intro_video source").attr("data-actualsrc"));
						intro_video.load();
						intro_video.play();
						
						$(".page").removeClass("show");
						$(".page").attr("style", "");
						
						$("body").addClass("blue");
						$("body").removeClass("white");
						
						hideHeader();
    				}
    				else
    				{
    					window.setPageState("intro-2", true);
    				}
    				break;
    			case "intro-2":
    				$(".intro_video_holder").addClass('done');
    				$(".page").addClass("show");
    				setTimeout(()=>{
    					$('#intro').addClass("step-1");
    				}, 100);
    				
    				setTimeout(()=>{
    					window.setPageState("intro-3");
    				}, 2500);
    				
    				$("body").addClass("blue");
    				$("body").removeClass("white");
    				
    				hideHeader();
    				
    				if (isDown)
    					bubble();
    				
    				
    				
    				break;
    			case "intro-3":
    				$(".intro_video_holder").addClass('done');
    				$('#intro').removeClass("step-1");
    				$('#intro').addClass("step-2");
    				
    				$("body").removeClass("noscroll");
    				
    				$("body").addClass("blue");
    				$("body").removeClass("white");
    				
    				hideHeader();
    				
    				resize();
    				
    				setTimeout(()=>{
						resize();
					}, 100);
					
					
					
    				break;
    			case "shoot-share-celebrate":
    				$(".intro_video_holder").addClass('done');
    				$(".page").addClass("show");
    				
    				$('#intro').removeClass("step-1");
    				$('#intro').addClass("step-2");
    				
    				$("body").removeClass("noscroll");
    				
    				$("body").addClass("blue");
    				$("body").removeClass("white");
    				
    				showHeader();
    				
    				/*
    				if (isDown)
    					bubble();
    				*/
    				break;
    			case "show-it-off":
    				$(".intro_video_holder").addClass('done');
    				$("body").removeClass("noscroll");
    				
    				$("body").addClass("white");
    				$("body").removeClass("blue");
    				
    				showHeader();
    				
    				/*
    				if (isDown)
    					bubble();
    				*/
    				
    				break;
    			case "explore":
    				$(".intro_video_holder").addClass('done');
					$("body").removeClass("noscroll");
					
					$("body").addClass("white");
    				$("body").removeClass("blue");
    				
    				showHeader();
    				
    				if (isDown)
    					bubble();
					
    				break;
    			case "enter":
    				$(".intro_video_holder").addClass('done');
    				$("body").removeClass("noscroll");
    				
    				$("body").addClass("white");
    				$("body").removeClass("blue");
    				
    				showHeader();
    				break;
    			case "thanks":
    				$(".intro_video_holder").addClass('done');
    				$("body").removeClass("noscroll");
    				
    				$("body").addClass("white");
    				$("body").removeClass("blue");
    				
    				showHeader();
    				break;
    			default:
    				changed = false;
    				break;
    			
    		}
    		
    		if (changed)
    		{
    			window.pageState = newState;
    			
    			console.log("pageState", newState);
    			
    			if (window.spritePos && window.showAnimation)
    			{
					for (let s=0; s<allSpriteIds.length; s++)
					{
						if (activeSprites.indexOf(allSpriteIds[s]) > -1 && spriteParent != null)
						{
							let target = spriteParent.find("."+allSpriteIds[s]+"-spacer");
						
							if (target.length)
							{
								let bounds = target[0].getBoundingClientRect();
							
								window.spritePos[allSpriteIds[s]][0] = bounds.left + (bounds.width)/2; //(target.offset().left + target.width()*scale/2) * scale;
								window.spritePos[allSpriteIds[s]][1] = bounds.top + (bounds.height)/2; //(target.offset().top + target.height()*scale/2) * scale;
								window.spritePos[allSpriteIds[s]][2] = bounds.width/window.spritePos[allSpriteIds[s]][3]; //scale;
								
								window.spritePos[allSpriteIds[s]][4] = maxV;
								
								if (maxV != 0)
									window.spritePos[allSpriteIds[s]][4] = maxV + (Math.random()*(maxV/2));
									
								if (target.attr("data-drift") == "0")
									window.spritePos[allSpriteIds[s]][5] = false
								else
									window.spritePos[allSpriteIds[s]][5] = true;
									
								window.spritePos[allSpriteIds[s]][6] = target;
									
									
							}
							else
							{
								console.log("could not find ",allSpriteIds[s]+"-spacer");
							}
						}
						else
						{
							if (window.spritePos[allSpriteIds[s]])
							{
								let offScreen = getOffScreen();
								
								window.spritePos[allSpriteIds[s]][0] = offScreen[0];
								window.spritePos[allSpriteIds[s]][1] = offScreen[1];
								
								window.spritePos[allSpriteIds[s]][6] = null;
							}
						}
					}
				}
    		}
    	}
    };
    
    $(window).on("resize", ()=>{
        resize();
    });

    resize();
    
    setTimeout(()=>{
		resize();
	}, 1000);
	
	window.lastScrollTop = true;
    
    let handleScroll = ()=>{
    	
    	
    	let windowHeight = $(window).height();
    	
    	let scrollTop = $("body").scrollTop();
    	let isDown = false;
    	
    	
    	if (scrollTop > window.lastScrollTop)
    		isDown = true;
    		
    	//console.log("isDown",isDown, scrollTop, window.lastScrollTop);
    	window.lastScrollTop = scrollTop;
    	
    	
    	if (($("#intro").offset().top - $(document).scrollTop()) < (windowHeight/2) &&
    		($("#intro").offset().top - $(document).scrollTop()) > (windowHeight/2) - windowHeight
    	)
    	{
    		if (window.pageState != "pre" &&
    			window.pageState != "start" &&
    			window.pageState != "intro-1" &&
    			window.pageState != "intro-2" &&
    			window.pageState != "intro-3")
	    		setPageState("intro-3", isDown);
    	}
    	else if (($("#celebrate").offset().top - $(document).scrollTop()) < (windowHeight/2) &&
    		($("#celebrate").offset().top - $(document).scrollTop()) > (windowHeight/2) - windowHeight
    	)
    	{
    		setPageState("shoot-share-celebrate", isDown);
    	}
    	else if (($("#showitoff").offset().top - $(document).scrollTop()) < (windowHeight/2) &&
    		($("#showitoff").offset().top - $(document).scrollTop()) > (windowHeight/2) - windowHeight
    	)
    	{
    		setPageState("show-it-off", isDown);
    	}
    	else if (($("#explore").offset().top - $(document).scrollTop()) < (windowHeight/2) &&
    		($("#explore").offset().top - $(document).scrollTop()) > (windowHeight/2) - windowHeight
    	)
    	{
    		setPageState("explore", isDown);
    	}
    	else if (entertowin && ($("#entertowin").offset().top - $(document).scrollTop()) < (windowHeight/2) &&
    		($("#entertowin").offset().top - $(document).scrollTop()) > (windowHeight/2) - windowHeight
    	)
    	{
    		setPageState("enter", isDown);
    	}
    };
    
    
    
    handleScroll();
    
    setTimeout(()=>{
    	resize();
    	handleScroll();
    }, 1000);
    
    
    /*
    setInterval(()=>{
    	handleScroll();
    }, 100);
    */
    
    
    
    $("body").on("scroll", handleScroll);
    
    
    //window.setPageState("shoot-share-celebrate");
    
    
    // animations
    if (window.showAnimation)
    {
		
		window.sprites = $("div.sprite");
		
		let frame = 0;
		let tickerPeriod = 1000;
		
		window.spriteRand = [];
		window.spritePos = {};
		window.scales = [];
		window.spriteState = {};
		
		for (let s=0; s<sprites.length; s++)
		{
			spriteRand.push(Math.round(Math.random()*1000));
			
			spritePos[$(sprites[s]).parent().attr("id")] = [$(window).width()/2, -500, 0.5, $(sprites[s]).parent().width(), 0, true, null];
		}
		
		setInterval(()=> {
			if (! window.paused)
			{
				sprites.show();
				frame++;
				
				
				
				//let stt = 0;
				
				let stt = $("body").scrollTop() * -1;
				
				//console.log("stt", stt);
				
				
				for (let s=0; s<sprites.length; s++)
				{
					let sprite = $(sprites[s]);
				
					let frames = parseInt(sprite.attr("data-frames"));
					let loop = sprite.attr("data-loop");
					let frame_class = sprite.attr("data-class");
					let local_frame = frame + spriteRand[s];
					let current_frame = local_frame % (frames-1);
					
					switch (loop)
					{
						case "reverse":
							let current_frame_double = local_frame % ((frames-1) * 2);
							//console.log(current_frame_double, ">", current_frame);
							if (current_frame_double > current_frame)
								current_frame = frames - current_frame;
						
							break;
						case "repeat":
						default:
							// no action required
							break;
					}
				
					//console.log("current_frame:",current_frame);
					
					//current_frame = 1;
					
					
					let current_frame_string = ""
					
					if (current_frame < 10)
						current_frame_string = "000"+current_frame;
					else
						current_frame_string = "00"+current_frame;
				
					frame_class = frame_class.replace("{f}", current_frame_string);
				
					sprite.attr("data-currentclass", sprite.parent().attr("id")+" sprite "+frame_class);
					//sprite.attr("class", sprite.parent().attr("id")+" sprite "+frame_class);
				}
					
					
					
					
				
				
				let requestId = requestAnimationFrame((e)=>{
					for (let s=0; s<sprites.length; s++)
					{
						let sprite = $(sprites[s]);
				
						sprite.attr("class", sprite.attr("data-currentclass"));
					}
				});
				
			}
			else {
				sprites.hide();
			}
			
		}, window.frame_rate);
		
		window.load_delay = 100;
	}
    
    window.setPageState("pre", true);
    
    
    window.preloadedImages = [];
    
    function preloadImages(urls, allImagesLoadedCallback, progress){
    	
	    if (window.showAnimation) {
			var loadedCounter = 0;
			var toBeLoadedNumber = urls.length;
			
			urls.forEach(function(url){
				//console.log("preload",url);
				window.preloadedImages.push(preloadImage(url, function(){
					loadedCounter++;
					//console.log('Number of loaded images: ' + loadedCounter);
					
					progress(loadedCounter/toBeLoadedNumber);
					
					if(loadedCounter == toBeLoadedNumber){
						allImagesLoadedCallback();
					}
				}));
			});
			
			function preloadImage(url, anImageLoadedCallback){
				var img = new Image();
				img.onload = anImageLoadedCallback;
				img.src = url;
				
				return img;
			}
		}
		else
		{
			allImagesLoadedCallback();
		}
	}
    
    window.startSite = () =>{
    	console.log("startSite");
    	
    	$(".loader .progress").attr("style","height: 0%;");
				
		resize();
		setTimeout(()=>{
			resize();
			$(".loader").addClass("done");
		}, window.load_delay);
		
		setTimeout(()=>{
			resize();
			
			window.setPageState("intro-1", true);
			resize();
		}, window.load_delay*2);
		
		setTimeout(()=>{
			resize();
		}, window.load_delay*3);
    
    };
    
    window.preloaded = false;
    
    preloadImages([
			"/images/animation/allthefeels-0-fs8.png",
			"/images/animation/allthefeels-1-fs8.png",
			"/images/animation/allthefeels-2-fs8.png",
			"/images/animation/allthefeels-3-fs8.png",
			"/images/animation/allthefeels-4-fs8.png",
			"/images/animation/allthefeels-5-fs8.png",
			
			"/images/animation/bow-0-fs8.png",
			"/images/animation/bow-1-fs8.png",
			"/images/animation/bow-2-fs8.png",
			"/images/animation/bow-3-fs8.png",
			"/images/animation/bow-4-fs8.png",
			"/images/animation/bow-5-fs8.png",
			"/images/animation/bow-6-fs8.png",
			"/images/animation/bow-7-fs8.png",
			"/images/animation/bow-8-fs8.png",
			"/images/animation/bow-9-fs8.png",
			"/images/animation/bow-10-fs8.png",
			"/images/animation/bow-11-fs8.png",
			"/images/animation/bow-12-fs8.png",
						
			"/images/animation/bubble-0-fs8.png",
			"/images/animation/bubble-1-fs8.png",
			"/images/animation/bubble-2-fs8.png",
			"/images/animation/bubble-3-fs8.png",
			"/images/animation/bubble-4-fs8.png",
			"/images/animation/bubble-5-fs8.png",
						
			"/images/animation/cloud-0-fs8.png",
			"/images/animation/cloud-1-fs8.png",
			"/images/animation/cloud-2-fs8.png",
			"/images/animation/cloud-3-fs8.png",
			"/images/animation/cloud-4-fs8.png",
			"/images/animation/cloud-5-fs8.png",
						
			"/images/animation/heart-0-fs8.png",
			"/images/animation/heart-1-fs8.png",
			"/images/animation/heart-2-fs8.png",
			"/images/animation/heart-3-fs8.png",
			"/images/animation/heart-4-fs8.png",
			"/images/animation/heart-5-fs8.png",
						
			"/images/animation/heart-green-0-fs8.png",
			"/images/animation/heart-green-1-fs8.png",
			"/images/animation/heart-green-2-fs8.png",
			"/images/animation/heart-green-3-fs8.png",
			"/images/animation/heart-green-4-fs8.png",
			"/images/animation/heart-green-5-fs8.png",
						
			"/images/animation/heart-yellow-0-fs8.png",
			"/images/animation/heart-yellow-1-fs8.png",
			"/images/animation/heart-yellow-2-fs8.png",
			"/images/animation/heart-yellow-3-fs8.png",
			"/images/animation/heart-yellow-4-fs8.png",
			"/images/animation/heart-yellow-5-fs8.png",
						
			"/images/animation/rainbox-0-fs8.png",
			"/images/animation/rainbox-1-fs8.png",
			"/images/animation/rainbox-2-fs8.png",
			"/images/animation/rainbox-3-fs8.png",
			"/images/animation/rainbox-4-fs8.png",
			"/images/animation/rainbox-5-fs8.png",
						
			"/images/animation/sphere-logo-0-fs8.png",
			"/images/animation/sphere-logo-1-fs8.png",
			"/images/animation/sphere-logo-2-fs8.png",
			"/images/animation/sphere-logo-3-fs8.png",
			"/images/animation/sphere-logo-4-fs8.png",
			"/images/animation/sphere-logo-5-fs8.png",
						
			"/images/animation/spheres-0-fs8.png",
			"/images/animation/spheres-1-fs8.png",
			"/images/animation/spheres-2-fs8.png",
			"/images/animation/spheres-3-fs8.png",
			"/images/animation/spheres-4-fs8.png",
			"/images/animation/spheres-5-fs8.png",
						
			"/images/animation/spiral-blue-0-fs8.png",
			"/images/animation/spiral-blue-1-fs8.png",
			"/images/animation/spiral-blue-2-fs8.png",
			"/images/animation/spiral-blue-3-fs8.png",
			"/images/animation/spiral-blue-4-fs8.png",
			"/images/animation/spiral-blue-5-fs8.png",
						
			"/images/animation/spiral-yellow-0-fs8.png",
			"/images/animation/spiral-yellow-1-fs8.png",
			"/images/animation/spiral-yellow-2-fs8.png",
			"/images/animation/spiral-yellow-3-fs8.png",
			"/images/animation/spiral-yellow-4-fs8.png",
			"/images/animation/spiral-yellow-5-fs8.png"
		], ()=>{
			window.preloaded = true;
		},
		(percent)=>{
			//console.log("percent",percent);
			$(".loader .progress").attr("style","height: "+(100-(100*percent))+"%;");
		}
	);
    
    if (window.loaded && window.preloaded)
    {
    	window.startSite();
    }
});


$(window).on("load", () => {
	console.log("loaded");
	window.loaded = true;
	
	if (window.startSite && window.preloaded)
		window.startSite();
});
